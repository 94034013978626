<template>
  <!-- 启用禁用局按钮 -->
  <div class="inline-block">
    <Popover v-if="option.showPopover" placement="top" @sureHandler="doClick($event)" class="left">
      <template #tip>
        <p>您确定要禁用操作吗？</p>
      </template>
      <template #reference="{ scope: loading }">
        <ColorTextBtn :loading="loading" type="primary" size="small"> {{ option.btnTxt }} </ColorTextBtn>
      </template>
    </Popover>
    <ColorTextBtn :loading="loading" type="primary" size="small" v-else @click="doClick">
      {{ option.btnTxt }}
    </ColorTextBtn>
    <AbleDialog
      :data="data"
      :sup_this="sup_this"
      :dialogVisible.sync="dialogVisible"
      v-if="dialogVisible"
      title="启用"
    />
  </div>
</template>

<script>
import AbleDialog from './ableDialog'
import { commonEheaderMixin } from '@/mixins'
import { PICTURE_GROUP_DISENABLE, PICTURE_GROUP_ENABLE } from '@/utils/constant'
import { disablePictureGroup } from '@/api/image/pictureGroupApi'

export default {
  components: {
    AbleDialog
  },

  mixins: [commonEheaderMixin],

  props: {
    data: {
      type: Object,
      required: true
    },

    sup_this: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    enable({ data }) {
      return data.enable || PICTURE_GROUP_DISENABLE
    },

    option({ enable }) {
      const { id, isDefault } = this.data
      console.log('isDefault', isDefault)
      const list = {
        [PICTURE_GROUP_ENABLE]: {
          btnTxt: '禁用',
          showPopover: true,
          doneFuncApi: disablePictureGroup,
          postData: {
            enable: PICTURE_GROUP_DISENABLE,
            id
          }
        },
        [PICTURE_GROUP_DISENABLE]: {
          btnTxt: '启用',
          showPopover: false,
          hasDialog: true
        }
      }
      return list[enable]
    }
  },

  methods: {
    async doClick() {
      const { postData, hasDialog, doneFuncApi } = this.option
      if (hasDialog) {
        return this.showDialog()
      }
      try {
        this.loading = true
        const res = await doneFuncApi(postData)
        if ($SUC(res)) {
          this.sup_this.init()
          this.$message.success('操作成功')
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
</style>